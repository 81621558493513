/** @jsx h */
import { h, Fragment } from "preact";
import { useContext, useEffect, useMemo, useRef, useState } from "preact/hooks";
import { renderPreact } from "../lib/render-preact";
import PageContext from "./PageContext";
import { translate, t, GeolocationTracking, PRestaurantSelection, LocalStorage, } from "restaumatic-client";
import Alert from "./Alert";
import { formatAddressAsSingleLine, } from "restaumatic-client/src";
import { observer } from "mobx-react";
import StreetAddressForm from "./StreetAddressForm";
import LocateMeButton from "./LocateMeButton";
import { Heading } from "./Heading";
import PreactCollapse from "./PreactCollapse";
import { withEditingParams } from "../utils/URL";
import { scrollIfAbove } from "../utils/Scroll";
const RESTAURANT_SELECTION_ID = "restaurant-selection";
const RestaurantSelection = observer((props) => {
    const { titleExtraClass = "", cardTitleExtraClass = "", cardExtraClass = "", listColumnNumber = 3, listHeadingLevel = "h3", cardHeadingLevel = "h4", cardButtonAriaLabel, version = "basic", onSelect, } = props;
    const settings = useContext(PageContext);
    const ref = useRef(null);
    const [isEditting, setIsEditting] = useState(false);
    const restaurantSelection = useMemo(() => {
        return PRestaurantSelection.create({
            ...settings,
            initialRestaurants: props.initialRestaurants,
            storage: LocalStorage.create(),
        });
    }, [settings, props.initialRestaurants]);
    const isFormVisible = isEditting ||
        (!isEditting && restaurantSelection.deliveringRestaurants.length === 0);
    useEffect(() => {
        if (restaurantSelection.formAddress !== null) {
            restaurantSelection.submitAddress();
        }
    }, [restaurantSelection]);
    const scrollToTheTop = () => {
        setTimeout(() => {
            if (ref.current !== null) {
                scrollIfAbove(ref.current);
            }
        });
    };
    // Focus on top of the restaurant selection module
    const focusRestaurantSelection = () => {
        if (ref.current !== null) {
            ref.current.focus({ preventScroll: true });
        }
    };
    const onEdit = () => {
        setIsEditting(true);
        scrollToTheTop();
        focusRestaurantSelection();
    };
    const handleLocateMe = () => {
        restaurantSelection.locateMe().then((succeeded) => {
            if (succeeded) {
                handleSubmit();
            }
        });
    };
    const handleSubmit = () => {
        setIsEditting(false);
        restaurantSelection.submitAddress().then(() => {
            if (restaurantSelection.deliveringRestaurants.length > 0) {
                scrollToTheTop();
            }
        });
        GeolocationTracking.findAddressEvent("RestaurantSelection", restaurantSelection.deliveryAddressState, restaurantSelection.formAddress);
    };
    return (h("div", { id: RESTAURANT_SELECTION_ID, ref: ref, className: "m-restaurants", tabIndex: -1, "aria-live": "polite" },
        isFormVisible && (h("article", { className: "m-restaurants__content" },
            h("div", { className: "m-restaurants__form-wrapper" },
                h(AddressForm, { titleExtraClass: titleExtraClass, listHeadingLevel: listHeadingLevel, controller: restaurantSelection.streetAddressController, isLoading: restaurantSelection.isLoading ||
                        restaurantSelection.geolocationIsLoading, geolocationError: restaurantSelection.geolocationError, onLocateMe: handleLocateMe, onSubmit: handleSubmit }),
                h(AddressStatus, { state: restaurantSelection.deliveryAddressState })))),
        restaurantSelection.deliveringRestaurants.length > 0 ? (h(Fragment, null,
            h(RestaurantList, { title: translate(t.restaurants.select.header_deliver), list: restaurantSelection.deliveringRestaurants, titleExtraClass: titleExtraClass, cardTitleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, listHeadingLevel: listHeadingLevel, cardHeadingLevel: cardHeadingLevel, cardButtonAriaLabel: cardButtonAriaLabel, version: version, doesRestaurantDeliver: true, onSelect: onSelect, onEdit: onEdit, address: restaurantSelection.submittedAddress }),
            h(RestaurantList, { title: translate(t.restaurants.select.header_others), list: restaurantSelection.notDeliveringRestaurants, titleExtraClass: titleExtraClass, cardTitleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, listHeadingLevel: listHeadingLevel, cardHeadingLevel: cardHeadingLevel, cardButtonAriaLabel: cardButtonAriaLabel, version: version, doesRestaurantDeliver: false, onSelect: onSelect, onEdit: onEdit, address: restaurantSelection.submittedAddress }))) : (h(RestaurantList, { title: translate(t.restaurants.select.header_all), showAddressInHeader: false, list: restaurantSelection.restaurantListItems, titleExtraClass: titleExtraClass, cardTitleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, listHeadingLevel: listHeadingLevel, cardHeadingLevel: cardHeadingLevel, cardButtonAriaLabel: cardButtonAriaLabel, version: version, address: restaurantSelection.submittedAddress, onSelect: onSelect }))));
});
const AddressStatus = observer((props) => {
    const { state } = props;
    switch (state.type) {
        case "NetworkError":
            return (h(Alert, { type: "danger", message: translate(t.errors.messages.network) }));
        case "AddressDoesNotExistOrInaccurate":
            return (h(Alert, { type: "danger", message: translate(t.delivery.geocoding_error) }));
        case "RestaurantDoesntDeliverTo": {
            const address = state.streetAddress;
            const message = `${translate(t.restaurants.select.error_address)} ${formatAddressAsSingleLine(address, address.isPostCodeRequired)}`;
            return h(Alert, { type: "danger", message: message });
        }
        default:
            return null;
    }
});
const AddressForm = observer((props) => {
    const { titleExtraClass, listHeadingLevel, controller, isLoading, geolocationError, onLocateMe, onSubmit, } = props;
    const handleSubmit = (e) => {
        e.preventDefault();
        onSubmit();
    };
    return (h(Fragment, null,
        h(Heading, { level: listHeadingLevel, className: `m-restaurants__heading ${titleExtraClass}`, id: "restaurant-selection-form-heading" }, translate(t.restaurants.select.title_find)),
        h("form", { "aria-controls": "restaurant-selection-list", "aria-labelledby": "restaurant-selection-form-heading", onSubmit: handleSubmit },
            h(LocateMeButton, { onLocateMe: onLocateMe, isLoading: isLoading, geolocationError: geolocationError }),
            h("div", { className: "m-delivery-address__container" },
                h(StreetAddressForm, { fieldId: "fulfillmentMethod.deliveryAddress", controller: controller })),
            h("button", { type: "submit", className: "btn btn-primary", "data-testid": "selectRestaurant/submit", disabled: isLoading }, translate(t.restaurants.select.find_restaurant)))));
});
function RestaurantList(props) {
    const { title, list, titleExtraClass, cardTitleExtraClass, cardExtraClass, listColumnNumber, listHeadingLevel, cardHeadingLevel, cardButtonAriaLabel, version, doesRestaurantDeliver, onSelect, onEdit, address, } = props;
    if (list.length === 0) {
        return h(Fragment, null);
    }
    return (h("article", { className: "m-restaurants__content" },
        h(Heading, { level: listHeadingLevel, className: `m-restaurants__heading ${titleExtraClass}`, id: "restaurant-selection-list-heading" },
            h("span", null,
                title,
                " "),
            address &&
                doesRestaurantDeliver !== false &&
                props.showAddressInHeader !== false && (h(Fragment, null,
                h("span", null,
                    formatAddressAsSingleLine(address, address.isPostCodeRequired),
                    "\u00A0"),
                h("button", { type: "button", className: "btn btn-link u-p0 u-box-shadow-none", onClick: onEdit, "data-testid": "selectRestaurant/changeAddress" }, "(" + translate(t.restaurants.select.button_change) + ")")))),
        h("ul", { className: "list-unstyled row row--flex row--vertical-gutter", id: "restaurant-selection-list", "aria-labelledby": "restaurant-selection-list-heading" }, list.map((item) => (h(RestaurantCard, { restaurant: item, titleExtraClass: cardTitleExtraClass, cardExtraClass: cardExtraClass, listColumnNumber: listColumnNumber, cardHeadingLevel: cardHeadingLevel, buttonAriaLabel: cardButtonAriaLabel, version: version, doesRestaurantDeliver: doesRestaurantDeliver, onSelect: onSelect, onEdit: onEdit, address: address }))))));
}
function RestaurantCard(props) {
    const { restaurant, doesRestaurantDeliver, titleExtraClass, cardExtraClass, listColumnNumber, cardHeadingLevel, buttonAriaLabel, version, onSelect, onEdit, address, } = props;
    const openingHours = restaurant.openingHours.split("\n");
    const columnGridWidth = 12 / listColumnNumber; // 12 is number of columns specified by Bootstrap Grid System
    const freeTresholdString = restaurant.freeTreshold
        ? ", " +
            translate(t.theme_defaults.delivery.info.free_treshold) +
            restaurant.freeTreshold
        : "";
    const deliveryCostDetailId = `delivery-cost-details-${restaurant.id}`;
    return (h("li", { className: `col-sm-6 col-md-${columnGridWidth}` },
        h("div", { class: `m-restaurants__card ${cardExtraClass}` },
            h(Heading, { level: cardHeadingLevel, className: `m-restaurants__card-title ${titleExtraClass}` }, restaurant.name),
            h("ul", { className: "u-list-unstyled u-flex-grow" },
                h("li", { className: "d-flex align-items-baseline u-my3" }, restaurant.address),
                version === "full" && (h(Fragment, null,
                    h("li", { className: "d-flex align-items-baseline u-my3" },
                        h("span", { className: "m-restaurants__card-icon icon-time icon-base", "aria-hidden": "true" }),
                        h("div", null, openingHours.map((line) => (h(Fragment, null,
                            line,
                            h("br", null)))))),
                    restaurant.phones.length > 0 && (h("li", { className: "d-flex align-items-baseline u-my3" },
                        h("span", { className: "m-restaurants__card-icon icon-phone icon-base", "aria-hidden": "true" }),
                        h("div", null, restaurant.phones.map((item) => (h("a", { href: `tel:${item}`, className: "u-block u-link-unstyled" }, item)))))),
                    restaurant.email && (h("li", { className: "d-flex align-items-baseline u-my3" },
                        h("span", { className: "m-restaurants__card-icon icon-mail icon-base", "aria-hidden": "true" }),
                        h("a", { href: `mailto:${restaurant.email}`, className: "u-link-unstyled u-text-ellipsis" }, restaurant.email))),
                    h("li", { className: "d-flex align-items-baseline u-my3" },
                        h("span", { className: "m-restaurants__card-icon icon-shopping-cart icon-base", "aria-hidden": "true" }),
                        h("span", null, restaurant.deliveryTypes)),
                    doesRestaurantDeliver === true && (h("li", { className: "d-flex align-items-baseline u-my3" },
                        h("span", { className: "m-restaurants__card-icon icon-truck icon-base", "aria-hidden": "true" }),
                        h("span", null,
                            h("span", { className: "d-flex" },
                                h("span", null,
                                    restaurant.deliveryCost,
                                    " (min.",
                                    " ",
                                    restaurant.minOrderValue,
                                    ")"),
                                h(PreactCollapse, { class: "m-restaurants__btn-collapse", target: `#${deliveryCostDetailId}` })),
                            h("div", { id: deliveryCostDetailId, className: "collapse" },
                                h("div", null, `${translate(t.theme_defaults.delivery.info.cost)}${restaurant.deliveryCost},`),
                                h("div", null, `${translate(t.theme_defaults.delivery.info.min_order_value)}${restaurant.minOrderValue}${freeTresholdString}`)))))))),
            address && doesRestaurantDeliver === false && (h(NoWithinAreaAlert, { onEdit: onEdit, address: address })),
            h("div", null,
                h("button", { className: "m-restaurants__card-action btn btn-primary", onClick: () => onSelect(restaurant), "data-testid": "selectRestaurant/select/" + restaurant.name, "aria-label": buttonAriaLabel }, translate(t.restaurants.select.button_select))))));
}
function NoWithinAreaAlert(props) {
    const { onEdit, address } = props;
    return (h(Fragment, null,
        h(Alert, { type: "warning", extraClasses: "u-my2", message: translate(t.delivery.not_possible, {
                address: formatAddressAsSingleLine(address, address.isPostCodeRequired),
            }), link: {
                label: `(${translate(t.restaurants.select.button_change)})`,
                path: "#" + RESTAURANT_SELECTION_ID,
                fieldId: "change-address-link",
                onClick: onEdit
                    ? (e) => {
                        e.preventDefault();
                        onEdit();
                    }
                    : undefined,
            } })));
}
export const SsrRestaurantSelection = renderPreact("RestaurantSelection", (props) => (h(RestaurantSelection, { ...props, onSelect: (restaurant) => window.location.assign(withEditingParams(new URL(restaurant.menuLink, window.location.origin))) })));
export default RestaurantSelection;
